import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DatePickerField = (props) => {
  const { formik, title, freeDays, formikId } = props;

  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    const formikDateValue = formik.values[formikId];
    if (formikDateValue) {
      setStartDate(new Date(formikDateValue));
    }
  }, [formik.values[formikId]]);

  const formatDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return null;
  };

  const isFreeDay = (date) => {
    const formattedDate = formatDate(date);
    return freeDays.includes(formattedDate);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    formik.setFieldValue(formikId, formatDate(date));
  };

  return (
    <div>
      <label className="block text-sm font-medium text-white mb-4">{title}</label>
      <DatePicker
        name={formikId}
        id={formikId}
        wrapperClassName="datePicker"
        dateFormat={'yyyy-MM-dd'}
        selected={startDate}
        onChange={handleDateChange}
        filterDate={isFreeDay}
        dayClassName={(date) => (isFreeDay(date) ? "bg-green-500 text-white" : undefined)}
      />
    </div>
  );
};

export default DatePickerField;
