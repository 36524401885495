import './App.css';
import FormHotels from "./FormHotels/FormHotels";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <FormHotels />
      </header>
    </div>
  );
}

export default App;




